// components/Navbar.js

import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Box, Flex, Link, Spacer, Button } from "@chakra-ui/react";
import { useAuth } from "../context/AuthContext";
import api from "../api/axios";
import { getToken } from "../utils/auth";

const Navbar = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const getUser = async () => {
    const token = getToken();
    if (!token) {
      return null;
    }
    try {
      const response = await api.get("/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching user data:", error.response || error);
      return null;
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      if (isAuthenticated) {
        const userData = await getUser();
        setUser(userData);
      }
    };

    fetchUser();
  }, [isAuthenticated]);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Box bg="teal.500" py={4}>
      <Flex maxW="container.lg" mx="auto" align="center">
        <Link as={RouterLink} to="/" color="white" fontWeight="bold" ml="20px">
          ET
        </Link>
        <Spacer />
        {isAuthenticated ? (
          <>
            {user && user.role === "admin" && (
              <Link as={RouterLink} to="/dashboard" color="white" mr={4}>
                Dash
              </Link>
            )}
            <Link as={RouterLink} to="/add-transaction" color="white" mr={4}>
              Add
            </Link>
            <Button
              onClick={handleLogout}
              colorScheme="green"
              appearance="subtle"
              size="sm"
              mr="20px"
            >
              Logout
            </Button>
          </>
        ) : (
          <>
            <Link as={RouterLink} to="/login" color="white" mr={4}>
              Login
            </Link>
            {/**
              <Link as={RouterLink} to="/register" color="white" mr="20px">
                  Register
              </Link>
            */}
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Navbar;
