// api/axios.js

import axios from "axios";
import { getToken, getUserRole } from "../utils/auth";

const api = axios.create({
  baseURL: "https://ride.carbonattic.com/api", // Update with your backend URL
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = getToken();
    const userRole = getUserRole();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    if (userRole === "Admin" && config.url.includes("/transactions")) {
      const accountType = config.params?.accountType || "main";
      config.params = {
        ...config.params,
        accountType, // Add accountType to params
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error("API Error:", error);
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
    } else if (error.request) {
      console.error("No response received:", error.request);
    } else {
      console.error("Error setting up request:", error.message);
    }
    return Promise.reject(error);
  }
);

export const getTransactions = (params = {}) => {
  console.log("Calling getTransactions with params:", params);
  return api
    .get("/transactions/all", { params })
    .then((response) => {
      console.log("getTransactions full response:", response);
      return response;
    })
    .catch((error) => {
      console.error("getTransactions error:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      }
      throw error;
    });
};

export const addTransaction = (transaction, accountType) => {
  if (!accountType) {
    throw new Error("accountType must be specified");
  }
  return api.post("/transactions", {
    ...transaction,
    accountType, // Add accountType to the request body
  });
};

export const updateTransaction = (id, transaction) => {
  if (transaction.type === "transfer") {
    return api.put(`/transactions/${id}`, {
      ...transaction,
      isTransfer: true,
    });
  } else {
    return api.put(`/transactions/${id}`, {
      ...transaction,
      isTransfer: false,
    });
  }
};

export const deleteTransaction = (id, accountType, isTransfer = false) => {
  if (!accountType) {
    throw new Error("accountType must be specified");
  }
  return api.delete(`/transactions/${id}`, {
    params: { accountType, isTransfer },
  });
};

export const getTotalWealth = () => {
  return api
    .get("/transactions/wealth-summary")
    .then((response) => {
      console.log("getTotalWealth full response:", response);
      return response.data;
    })
    .catch((error) => {
      console.error("getTotalWealth error:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      }
      throw error;
    });
};

export const getAccounts = () => {
  return api
    .get("/accounts")
    .then((response) => {
      console.log("getAccounts full response:", response);
      return response.data;
    })
    .catch((error) => {
      console.error("getAccounts error:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      }
      throw error;
    });
};

export const getAccountBalances = () => {
  return api
    .get("/accounts/balances")
    .then((response) => {
      console.log("getAccountBalances full response:", response);
      return response.data;
    })
    .catch((error) => {
      console.error("getAccountBalances error:", error);
      if (error.response) {
        console.error("Error response data:", error.response.data);
        console.error("Error response status:", error.response.status);
        console.error("Error response headers:", error.response.headers);
      }
      throw error;
    });
};

export const transferFunds = async (fromAccountId, toAccountId, amount) => {
  try {
    const response = await api.post("/accounts/transfer", {
      fromAccountId,
      toAccountId,
      amount,
    });
    console.log("Transfer Funds Response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Transfer Funds Error:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deleteTransferTransaction = async (id, accountType) => {
  try {
    const response = await api.delete(`/transactions/${id}`, {
      params: { accountType, isTransfer: true },
    });
    console.log("Delete Transfer Transaction Response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Delete Transfer Transaction Error:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateTransferTransaction = async (id, transaction) => {
  try {
    const response = await api.put(`/transactions/${id}`, {
      ...transaction,
      isTransfer: true,
    });
    console.log("Update Transfer Transaction Response:", response.data);
    return response.data;
  } catch (error) {
    console.error(
      "Update Transfer Transaction Error:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export default api;
