// components/Dashboard.js

import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Heading,
  Text,
  SimpleGrid,
  useToast,
  Spinner,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  VStack,
  HStack,
  Badge,
  useBreakpointValue,
  Input,
  Flex,
  Collapse,
  Container,
  Icon,
} from "@chakra-ui/react";
import { FaList } from "react-icons/fa";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import {
  getTransactions,
  updateTransaction,
  deleteTransaction,
  getTotalWealth,
  getAccountBalances,
  updateTransferTransaction,
  deleteTransferTransaction,
} from "../api/axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { ErrorBoundary } from "react-error-boundary";
import EditTransaction from "./EditTransaction";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#8884D8"];

const Dashboard = () => {
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transactionToEdit, setTransactionToEdit] = useState(null);
  const toast = useToast();
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [transactionToDelete, setTransactionToDelete] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState("main");
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [month, setMonth] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [incomeVsExpense, setIncomeVsExpense] = useState([]);
  const [totalWealth, setTotalWealth] = useState(null);
  const [allMonthTransactions, setAllMonthTransactions] = useState([]);
  const [isMonthSelectionOpen, setIsMonthSelectionOpen] = useState(false);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const [accountBalances, setAccountBalances] = useState([]);

  const toggleMonthSelection = () => {
    setIsMonthSelectionOpen(!isMonthSelectionOpen);
    if (isDateRangeOpen) setIsDateRangeOpen(false);
  };

  const toggleDateRange = () => {
    setIsDateRangeOpen(!isDateRangeOpen);
    if (isMonthSelectionOpen) setIsMonthSelectionOpen(false);
  };

  const handleMonthChange = (e) => {
    const selectedMonth = e.target.value;
    setMonth(selectedMonth);
    setStartDate("");
    setEndDate("");
    setPage(1);
    fetchTransactions(selectedAccount);
    fetchAllMonthTransactions(selectedAccount);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setMonth("");
    setPage(1);
    if (endDate) {
      fetchTransactions(selectedAccount);
      fetchAllMonthTransactions(selectedAccount);
    }
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setMonth("");
    setPage(1);
    if (startDate) {
      fetchTransactions(selectedAccount);
      fetchAllMonthTransactions(selectedAccount);
    }
  };

  const handleAccountChange = (event) => {
    const newAccount = event.target.value;
    console.log("Account changed to:", newAccount);
    setSelectedAccount(newAccount);
    fetchTransactions(newAccount);
  };

  const fetchAllMonthTransactions = useCallback(
    async (accountType) => {
      try {
        let params = { accountType };
        let filterDescription = "";

        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        const currentYear = currentDate.getFullYear();

        if (month) {
          params.month = month;
          params.year = currentYear;
          filterDescription = `Month: ${new Date(
            currentYear,
            month - 1
          ).toLocaleString("default", { month: "long", year: "numeric" })}`;
        } else if (startDate && endDate) {
          params.startDate = startDate;
          params.endDate = endDate;
          filterDescription = `Date range: ${startDate} to ${endDate}`;
        } else {
          params.month = currentMonth;
          params.year = currentYear;
          filterDescription = `Current month: ${currentDate.toLocaleString(
            "default",
            { month: "long", year: "numeric" }
          )}`;
        }

        console.log(`Fetching transactions with params:`, params);
        console.log(`Filter: ${filterDescription}`);

        const allTransactionsResponse = await getTransactions(params);

        let allTransactions = Array.isArray(allTransactionsResponse.data)
          ? allTransactionsResponse.data
          : allTransactionsResponse.data?.transactions || [];

        console.log(
          `Number of transactions for ${filterDescription}: ${allTransactions.length}`
        );

        setAllMonthTransactions(allTransactions);

        let income = 0;
        let expense = 0;

        allTransactions.forEach((t) => {
          const amount = parseFloat(t.amount);
          if (
            t.type === "income" ||
            (t.type === "transfer" && t.category === "Transfer In")
          ) {
            income += amount;
          } else if (
            t.type === "expense" ||
            (t.type === "transfer" && t.category === "Transfer Out")
          ) {
            expense += amount;
          }
        });

        setIncomeVsExpense([
          { name: "Income", amount: income },
          { name: "Expense", amount: expense },
        ]);

        console.log(`Total Income for ${filterDescription}: ${income}`);
        console.log(`Total Expense for ${filterDescription}: ${expense}`);

        // Calculate pie chart data from allMonthTransactions
      } catch (error) {
        console.error("Error fetching transactions:", error);
        toast({
          title: "Error fetching transactions",
          description: "Unable to fetch transactions for the selected period.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [month, startDate, endDate, toast]
  );

  const fetchTransactions = useCallback(
    async (accountType) => {
      console.log("Fetching paginated transactions...");
      setIsLoading(true);
      setError(null);
      try {
        const params = { page, accountType };
        if (month) {
          params.month = month;
        } else if (startDate && endDate) {
          params.startDate = startDate;
          params.endDate = endDate;
        }

        // Fetch paginated transactions
        const paginatedResponse = await getTransactions(params);
        console.log("Paginated response:", paginatedResponse);

        if (Array.isArray(paginatedResponse.data)) {
          // If the response is an array, it's the transactions directly
          setTransactions(paginatedResponse.data);
          // You might need to adjust how you're getting the total pages
          // For now, we'll assume there's only one page if we get an array
          setTotalPages(1);
        } else if (
          paginatedResponse.data &&
          Array.isArray(paginatedResponse.data.transactions)
        ) {
          // If it's still in the old format with a transactions property
          setTransactions(paginatedResponse.data.transactions);
          setTotalPages(paginatedResponse.data.totalPages || 1);
        } else {
          console.error(
            "Unexpected response structure:",
            paginatedResponse.data
          );
          setError("Received unexpected data structure from server");
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
        let errorMessage = "An error occurred while fetching transactions.";
        if (error.response) {
          if (error.response.status === 500) {
            errorMessage =
              "Server error. Please try again later or contact support.";
          } else {
            errorMessage =
              error.response.data?.message || "An unexpected error occurred.";
          }
        } else if (error.request) {
          errorMessage =
            "No response from server. Please check your internet connection.";
        }
        setError(errorMessage);
        toast({
          title: "Error fetching transactions",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setTransactions([]);
        setIncomeVsExpense([]);
      } finally {
        setIsLoading(false);
      }
    },
    [page, month, startDate, endDate, toast]
  );

  const showErrorToast = useCallback(
    (description) => {
      toast({
        title: "Error fetching total wealth",
        description,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
    [toast]
  );

  const fetchAccountBalances = useCallback(async () => {
    try {
      const response = await getAccountBalances();
      if (response && response.balanceSummary) {
        setAccountBalances(response.balanceSummary);
      } else {
        console.error(
          "Error fetching account balances: Invalid response format",
          response
        );
        showErrorToast("Unable to retrieve account balances.");
      }
    } catch (error) {
      console.error("Error fetching account balances:", error);
      showErrorToast("Unable to retrieve account balances.");
    }
  }, [showErrorToast]);

  const fetchTotalWealth = useCallback(async () => {
    try {
      const response = await getTotalWealth();
      if (response && typeof response.totalWealth === "number") {
        setTotalWealth(response.totalWealth);
      } else {
        console.error(
          "Error fetching total wealth: Invalid response format",
          response
        );
        showErrorToast("Unable to retrieve total wealth across accounts.");
      }
    } catch (error) {
      console.error("Error fetching total wealth:", error);
      showErrorToast("Unable to retrieve total wealth across accounts.");
    }
  }, [showErrorToast]);

  useEffect(() => {
    console.log(
      "useEffect triggered, isAuthenticated:",
      isAuthenticated,
      "user:",
      user
    );

    if (!isAuthenticated || user === null) {
      if (!isAuthenticated) {
        console.log("Not authenticated, navigating to login");
        navigate("/login");
      } else {
        console.log("User data not yet loaded, waiting...");
      }
      return;
    }

    const isAdmin = user.role.toLowerCase() === "admin";
    console.log("Is user admin?", isAdmin);

    if (!isAdmin) {
      console.log("User is not admin, navigating to dashboard");
      navigate("/dashboard");
      return;
    }

    const fetchData = async () => {
      try {
        await Promise.all([
          fetchTotalWealth(),
          fetchTransactions(selectedAccount),
          fetchAllMonthTransactions(selectedAccount),
          fetchAccountBalances(),
        ]);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error (e.g., show a toast notification)
      }
    };

    fetchData();
  }, [
    isAuthenticated,
    user,
    navigate,
    fetchTotalWealth,
    fetchTransactions,
    fetchAllMonthTransactions,
    fetchAccountBalances,
    selectedAccount,
    month,
    startDate,
    endDate,
  ]);

  const formatAmount = (amount) => {
    // Check if amount is undefined or null
    if (amount === undefined || amount === null) {
      return "N/A"; // Return "N/A" for undefined or null amounts
    }

    // Check if amount is a number
    if (typeof amount !== "number") {
      console.warn(`Invalid amount type: ${typeof amount}`);
      return "Error"; // Return "Error" for non-number amounts
    }

    // If amount is valid, format it
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "UTC",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const totalIncome = transactions
    .filter((t) => t.type === "income")
    .reduce((sum, t) => sum + t.amount, 0);

  const totalExpenses = transactions
    .filter((t) => t.type === "expense")
    .reduce((sum, t) => sum + t.amount, 0);

  const expensesByCategory = transactions
    .filter((t) => t.type === "expense")
    .reduce((acc, t) => {
      acc[t.category] = (acc[t.category] || 0) + t.amount;
      return acc;
    }, {});

  useEffect(() => {
    console.log("Expenses by category:", expensesByCategory);
  }, [expensesByCategory]);

  const pieChartData = Object.entries(
    allMonthTransactions
      .filter((t) => t.type === "expense")
      .reduce((acc, t) => {
        acc[t.category] = (acc[t.category] || 0) + t.amount;
        return acc;
      }, {})
  ).map(([category, amount]) => ({
    name: category,
    value: amount,
  }));

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box bg="white" p={2} borderRadius="md" boxShadow="md">
          <Text>{`${payload[0].name}: ${formatAmount(payload[0].value)}`}</Text>
        </Box>
      );
    }
    return null;
  };

  const handleEdit = (transaction) => {
    setTransactionToEdit(transaction);
    onEditOpen();
  };

  const handleDelete = (transactionId) => {
    setTransactionToDelete(transactionId);
    onDeleteOpen();
  };

  const confirmDelete = async () => {
    try {
      const transactionToDeleteObj = transactions.find(
        (t) => t._id === transactionToDelete
      );
      if (transactionToDeleteObj.type === "transfer") {
        await deleteTransferTransaction(transactionToDelete, selectedAccount);
      } else {
        await deleteTransaction(transactionToDelete, selectedAccount, false);
      }
      setTransactions(
        transactions.filter((t) => t._id !== transactionToDelete)
      );
      toast({
        title: "Transaction deleted",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      // Fetch updated total wealth and transactions
      await Promise.all([
        fetchTotalWealth(),
        fetchTransactions(selectedAccount),
        fetchAllMonthTransactions(selectedAccount),
        fetchAccountBalances(),
      ]);
    } catch (error) {
      console.error("Error deleting transaction:", error);
      toast({
        title: "Error deleting transaction",
        description:
          error.response?.data?.message || "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    onDeleteClose();
  };

  const handleUpdateTransaction = async (updatedTransaction) => {
    try {
      let response;
      if (updatedTransaction.type === "transfer") {
        response = await updateTransferTransaction(
          updatedTransaction._id,
          updatedTransaction
        );
      } else {
        response = await updateTransaction(
          updatedTransaction._id,
          updatedTransaction
        );
      }

      if (response && (response.data || response.transaction)) {
        const updatedData = response.data || response.transaction;
        setTransactions(
          transactions.map((t) =>
            t._id === updatedTransaction._id ? updatedData : t
          )
        );
        onEditClose();
        toast({
          title: "Transaction updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        // Fetch updated total wealth and transactions
        await Promise.all([
          fetchTotalWealth(),
          fetchTransactions(selectedAccount),
          fetchAllMonthTransactions(selectedAccount),
          fetchAccountBalances(),
        ]);
      } else {
        console.error("Unexpected response structure:", response);
        throw new Error("Invalid or unexpected response from server");
      }
    } catch (error) {
      console.error("Error updating transaction:", error);
      toast({
        title: "Error updating transaction",
        description:
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    console.log("Dashboard re-rendered. isLoading:", isLoading);
  }, [isLoading, transactions]);

  useEffect(() => {
    console.log("Dashboard mounted");
    return () => console.log("Dashboard unmounted");
  }, []);

  return (
    <ErrorBoundary fallback={<div>Something went wrong</div>}>
      <Box
        maxWidth="1200px"
        margin="auto"
        p={4}
        borderRadius="xl"
        boxShadow="xl"
      >
        <Container maxW="container.xl" px={2}>
          <VStack spacing={1} align="stretch">
            {/* Filter Controls */}
            <Flex justifyContent="flex-end" mb={0} mt={0}>
              <Button
                variant="ghost"
                size="xs"
                onClick={toggleMonthSelection}
                mr={1}
              >
                Month Selection
              </Button>
              <Button variant="ghost" size="xs" onClick={toggleDateRange}>
                Date Range
              </Button>
            </Flex>

            {/* Month Selection Collapse */}
            <Collapse in={isMonthSelectionOpen} animateOpacity>
              <Box mb={4}>
                <Select
                  placeholder="Select month"
                  value={month}
                  onChange={handleMonthChange}
                  bg="gray.100"
                  borderRadius="md"
                >
                  {[...Array(12)].map((_, i) => (
                    <option key={i} value={i + 1}>
                      {new Date(0, i).toLocaleString("default", {
                        month: "long",
                      })}
                    </option>
                  ))}
                </Select>
              </Box>
            </Collapse>

            {/* Date Range Collapse */}
            <Collapse in={isDateRangeOpen} animateOpacity>
              <Flex mb={4} flexDirection={isMobile ? "column" : "row"} gap={3}>
                <Input
                  type="date"
                  value={startDate}
                  onChange={handleStartDateChange}
                  bg="gray.100"
                  borderRadius="md"
                />
                <Input
                  type="date"
                  value={endDate}
                  onChange={handleEndDateChange}
                  bg="gray.100"
                  borderRadius="md"
                />
              </Flex>
            </Collapse>

            {/* Total Wealth and Account Balances Summary */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={6}>
              <Box
                bgGradient="linear(to-r, purple.400, pink.500)"
                p={6}
                borderRadius="lg"
                boxShadow="md"
              >
                <Heading size="md" mb={3} color="white">
                  Total Wealth (All Accounts)
                </Heading>
                <Text fontWeight="bold" fontSize="2xl" color="white">
                  {totalWealth !== null
                    ? formatAmount(totalWealth)
                    : "Loading..."}
                </Text>
              </Box>
              <Box
                bg="white"
                p={6}
                borderRadius="lg"
                boxShadow="md"
                border="1px"
                borderColor="blue.100"
              >
                <Heading size="md" mb={3} color="blue.600">
                  Account Balances
                </Heading>
                {accountBalances.map((account) => {
                  if (account.accountId === "668bb74335a72b7530fce622") {
                    return (
                      <Flex
                        key={account.accountId}
                        justify="space-between"
                        mb={2}
                      >
                        <Text fontWeight="medium">{account.accountName}</Text>
                        <Text fontWeight="bold" color="blue.500">
                          {formatAmount(
                            (incomeVsExpense[0]?.amount || 0) -
                              (incomeVsExpense[1]?.amount || 0)
                          )}
                        </Text>
                      </Flex>
                    );
                  }
                  return (
                    <Flex
                      key={account.accountId}
                      justify="space-between"
                      mb={2}
                    >
                      <Text fontWeight="medium">{account.accountName}</Text>
                      <Text fontWeight="bold" color="blue.500">
                        {account.balance !== undefined
                          ? formatAmount(account.balance)
                          : "N/A"}
                      </Text>
                    </Flex>
                  );
                })}
              </Box>
            </SimpleGrid>

            {/* Admin Account Selection */}
            {user && user.role.toLowerCase() === "admin" && (
              <Select
                value={selectedAccount}
                onChange={handleAccountChange}
                mt={2}
                mb={1}
                bg="gray.100"
                borderRadius="md"
              >
                <option value="main">Cash</option>
                <option value="aik">AIK</option>
                <option value="unicredit">Unicredit</option>
              </Select>
            )}

            {/* Content */}
            {isLoading ? (
              <Flex justify="center" align="center" height="50vh">
                <Spinner size="xl" />
                <Text ml={3}>Loading transactions...</Text>
              </Flex>
            ) : error ? (
              <Box textAlign="center" color="red.500">
                <Text>{error}</Text>
              </Box>
            ) : (
              <VStack spacing={4} align="stretch">
                {/* Summary */}
                <Box bg="gray.700" color="white" p={4} borderRadius="md">
                  <Heading size="sm" mb={2} color="gray.100">
                    Summary (
                    {month
                      ? new Date(0, month - 1).toLocaleString("default", {
                          month: "long",
                        })
                      : startDate && endDate
                      ? `${startDate} to ${endDate}`
                      : new Date().toLocaleString("default", { month: "long" })}
                    )
                  </Heading>
                  <Text>
                    Total Income:{" "}
                    {formatAmount(incomeVsExpense[0]?.amount || 0)}
                  </Text>
                  <Text>
                    Total Expenses:{" "}
                    {formatAmount(incomeVsExpense[1]?.amount || 0)}
                  </Text>
                  <Text fontWeight="bold">
                    Balance:{" "}
                    {formatAmount(
                      (incomeVsExpense[0]?.amount || 0) -
                        (incomeVsExpense[1]?.amount || 0)
                    )}
                  </Text>
                </Box>

                {/* Charts */}
                {(pieChartData.length > 0 ||
                  totalIncome > 0 ||
                  totalExpenses > 0) && (
                  <Box
                    bg="white"
                    p={6}
                    borderRadius="lg"
                    boxShadow="md"
                    mb={6}
                    border="1px"
                    borderColor="gray.200"
                  >
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                      <Box>
                        <Heading size="sm" mb={4} color="teal.500">
                          Expense Breakdown
                        </Heading>
                        {pieChartData.length > 0 ? (
                          <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                              <Pie
                                data={pieChartData}
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={80}
                                fill="#82ca9d"
                                label={({ name, percent }) =>
                                  `${name} ${(percent * 100).toFixed(0)}%`
                                }
                              >
                                {pieChartData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                              <Tooltip content={<CustomTooltip />} />
                            </PieChart>
                          </ResponsiveContainer>
                        ) : (
                          <Text>No expense data available</Text>
                        )}
                      </Box>
                    </SimpleGrid>
                  </Box>
                )}

                {/* Recent Transactions */}
                <Box
                  bg="white"
                  p={6}
                  borderRadius="lg"
                  boxShadow="md"
                  border="1px"
                  borderColor="gray.200"
                >
                  <Flex align="center" mb={4}>
                    <Icon as={FaList} mr={2} color="orange.500" />
                    <Heading size="md" color="orange.600">
                      Transactions ({selectedAccount})
                    </Heading>
                  </Flex>
                  {transactions.length > 0 ? (
                    <VStack spacing={4} align="stretch">
                      {transactions.map((transaction) => (
                        <Box
                          key={transaction._id}
                          borderWidth={1}
                          borderRadius="md"
                          p={4}
                          _hover={{ boxShadow: "md" }}
                          transition="all 0.2s"
                          bg={
                            transaction.type === "income"
                              ? "green.50"
                              : transaction.type === "expense"
                              ? "red.50"
                              : "white"
                          }
                        >
                          <Flex justify="space-between" align="center">
                            <Box>
                              <Text fontWeight="bold" fontSize="lg">
                                {formatDate(transaction.date)}
                              </Text>
                              <Text color="gray.600">
                                {transaction.category}
                              </Text>
                              <Text color="gray.500" fontSize="sm">
                                {transaction.description}
                              </Text>
                            </Box>
                            <Box textAlign="right">
                              <Badge
                                colorScheme={
                                  transaction.type === "income"
                                    ? "green"
                                    : transaction.type === "expense"
                                    ? "red"
                                    : "blue"
                                }
                                mb={2}
                              >
                                {transaction.type}
                              </Badge>
                              <Text
                                fontWeight="bold"
                                fontSize="xl"
                                color={
                                  transaction.type === "income"
                                    ? "green.500"
                                    : transaction.type === "expense"
                                    ? "red.500"
                                    : "blue.500"
                                }
                              >
                                {formatAmount(transaction.amount)}
                              </Text>
                              <HStack spacing={2} mt={2} justify="flex-end">
                                <Button
                                  size="sm"
                                  colorScheme="blue"
                                  onClick={() => handleEdit(transaction)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  size="sm"
                                  colorScheme="red"
                                  onClick={() => handleDelete(transaction._id)}
                                >
                                  Delete
                                </Button>
                              </HStack>
                            </Box>
                          </Flex>
                        </Box>
                      ))}
                    </VStack>
                  ) : (
                    <Text>No transactions found.</Text>
                  )}

                  {/* Pagination */}
                  <Flex justify="space-between" mt={4}>
                    <Button
                      onClick={() => setPage((p) => Math.max(1, p - 1))}
                      isDisabled={page === 1}
                      variant="ghost"
                    >
                      Previous
                    </Button>
                    <Text>
                      Page {page} of {totalPages}
                    </Text>
                    <Button
                      onClick={() =>
                        setPage((p) => Math.min(totalPages, p + 1))
                      }
                      isDisabled={page === totalPages}
                      variant="ghost"
                    >
                      Next
                    </Button>
                  </Flex>
                </Box>
              </VStack>
            )}

            {/* Modals */}
            <Modal isOpen={isEditOpen} onClose={onEditClose} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Transaction</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  {transactionToEdit && (
                    <EditTransaction
                      transaction={transactionToEdit}
                      onUpdate={handleUpdateTransaction}
                      onCancel={onEditClose}
                      accountType={selectedAccount}
                    />
                  )}
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Delete Transaction</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  Are you sure you want to delete this transaction?
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="red" mr={3} onClick={confirmDelete}>
                    Delete
                  </Button>
                  <Button variant="ghost" onClick={onDeleteClose}>
                    Cancel
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </VStack>
        </Container>
      </Box>
    </ErrorBoundary>
  );
};

export default Dashboard;
